// @flow strict

import { CancelToken } from 'axios';
import type { Placeholder, Question } from '@omq/flow';
import { Backend, TrackingAPI } from '@omq/shared';

type ContactSearchParams = {
  category?: number,
};

export type ContactSearchResult = {
  questions: Array<Question> | null,
  language?: string | null,
  inputTooShort: boolean,
};

/**
 * API functions to make requests to /contact.
 */
export const ContactAPI = {
  ...TrackingAPI,

  /**
   * Send search request to server.
   *
   * @param {Backend} backend - Backend object
   * @param {string|null} text - search text
   * @param {Placeholder} placeholder - placeholders for answers
   * @param {ContactSearchParams} params - search params
   * @param {?CancelToken} cancelToken - cancel token
   *
   * @returns {Promise<ContactSearchResult>}
   */
  search: (
    backend: Backend,
    text: string | null,
    placeholder: Placeholder,
    params: ContactSearchParams,
    cancelToken: ?CancelToken,
  ): Promise<ContactSearchResult> => {
    const data = { text: text === '' ? null : text, values: placeholder };
    const config = {};

    // add cancel token if present
    /* istanbul ignore else */
    if (cancelToken != null) {
      config.cancelToken = cancelToken;
    }

    // set request params
    config.params = params;

    // send request
    return backend.post<ContactSearchResult>(`search`, data, config);
  },

  /**
   * Get a single question.
   *
   * @param {Backend} backend - Backend object
   * @param {number} id - id of the question
   * @param {Placeholder} placeholder - placeholders for answers
   *
   * @returns {Promise<Question>}
   */
  question: (
    backend: Backend,
    id: number,
    placeholder: Placeholder,
  ): Promise<Question> => {
    // send request
    return backend.post<Question>(`search/${id}`, { values: placeholder }, {});
  },
};
