/* istanbul ignore file */

import { documentReady, getBaseUrl } from '@omq/shared';

// set webpack public path option
// see here: https://webpack.js.org/guides/public-path/
documentReady().then(() => {
  const { _contact: config } = window;

  let account;

  // check if config is set via js
  if (config != null) {
    account = config.account;
  } else {
    // or read account from html element
    const element = document.querySelector('#omq-contact-container');
    if (element != null) {
      account = element.getAttribute('data-omq-contact-account');
    }
  }

  // set public path with account
  // eslint-disable-next-line
  __webpack_public_path__ = `${getBaseUrl(account || 'omq')}/contact/`;
});
