// @flow strict

import { EventDispatcher } from '@omq/shared';

import type { ContactSearchResult } from '../api/contact';
import { EventNames } from '@omq/shared/src/event/event-dispatcher';

/**
 * Type for contact category.
 */
export type ContactCategory = {
  // id of category
  id: number,

  // name of category (language specified in request)
  name: string,
};
/**
 * Event dispatcher for Contact.
 *
 * @class
 * @since 9.6
 * @author Florian Walch
 */
export class ContactEvents extends EventDispatcher<ContactSearchResult> {
  /**
   * Dispatch event for received search result.
   *
   * @param {string} searchValue - text used for search
   * @param {ContactSearchResult} result - contact search result
   */
  dispatchSearchLoaded(searchValue: string, result: ContactSearchResult) {
    const {questions} = result;

    // notify subscribers with search value & found questions
    this.dispatchEvent(EventNames.SEARCH_LOADED_EVENT, {
      questions,
      searchValue,
    });
  }

  /**
   * Dispatch event for received categories.
   *
   * @param {Array<ContactCategory>} categories - list of categories
   */
  dispatchCategoriesLoaded(categories: Array<ContactCategory>) {
     this.dispatchEvent(EventNames.CATEGORIES_LOADED_EVENT, { categories });
  }

}