// @flow strict

import { type Node, createRef, StrictMode } from 'react';
import ReactDOM from 'react-dom';

import type { InitialData, Placeholder, Question } from '@omq/flow';
import {
  BackendWrapper,
  ErrorBoundary,
  ErrorHandling,
  getBaseUrl,
  type ErrorDetails,
  InitialDataWrapper,
  EventWrapper,
} from '@omq/shared';

import { Contact, type ContactRefType } from './contact';
import { SubmitHelpSession } from './components/submit-help-session/submit-help-session';

import { DSN } from './error/sentry';
import { ContactEvents } from './events/contact-events';
import type { ContactCategory } from './events/contact-events';

/**
 * Type of Contact configuration.
 */
type ContactConfiguration = {
  container: string,
  account: ?string,
  apiKey: ?string,
  analyze: ?string,
  submit: ?string,
  cookieIsEnabled?: boolean,
  categories: Array<ContactCategory>,
};

/**
 * Default css selector for contact container
 *
 * @type {string}
 */
const DEFAULT_CONTACT_CONTAINER =
  '#omq-contact-container, #userlike-contact-container';

const ContactConfigAttributeNames = {
  ACCOUNT: 'contact-account',
  APIKEY: 'contact-api-key',
  ANALYZE: 'contact-analyze',
  SUBMIT: 'contact-submit',
  COOKIE_ENABLED: 'contact-cookie-enabled',
};

// get element attribute for given name
// check for different names depending on integration
function getDataAttribute(element, attributeName) {
  return (
    element.getAttribute(`data-omq-${attributeName}`) ||
    element.getAttribute(`data-userlike-${attributeName}`) ||
    null
  );
}

// get element attribute for given name
// check for different names depending on integration
function setDataAttribute(element, attributeName, value) {
  const integrationName = element.hasAttribute(`data-userlike-${attributeName}`)
    ? 'userlike'
    : 'omq';

  element.setAttribute(`data-${integrationName}-${attributeName}`, value);
}

const contactReference = createRef<ContactRefType>();

export type ContactInitialData = InitialData & {
  questions: Array<Question>,
};

/**
 * Read Contact configuration from global var.
 *
 * Global Contact configuration looks like this:
 *   _contact = {
 *     container: 'CSS_SELECTOR', // default #omq-contact-container
 *     account: 'NAME|URL',
 *     apiKey: 'API_KEY',
 *   };
 *
 * @returns {?ContactConfiguration}
 */
function readConfigurationFromGlobal(): ?ContactConfiguration {
  const config = window.userlikeContact || window._contact;

  // check if config is set
  if (config == null) {
    return null;
  }

  // get values
  const {
    container,
    account,
    apiKey,
    analyze,
    submit,
    cookieIsEnabled,
    categories,
  } = config;

  // return config
  return {
    container: container || DEFAULT_CONTACT_CONTAINER,
    account,
    apiKey,
    analyze,
    submit,
    cookieIsEnabled,
    categories,
  };
}

/**
 * Read Contact config from HTML element.
 *
 * The element with contact configuration looks like this:
 *   <div id="omq-contact-container"
 *        data-omq-contact-account="NAME|URL"
 *        data-omq-contact-api-key="API_KEY">
 *   </div>
 *
 * @returns {?ContactConfiguration}
 */
function readConfigurationFromAttributes(): ?ContactConfiguration {
  // get element
  const element = document.querySelector(DEFAULT_CONTACT_CONTAINER);
  if (element == null) {
    return null;
  }

  // get attributes
  const account = getDataAttribute(
    element,
    ContactConfigAttributeNames.ACCOUNT,
  );
  const apiKey = getDataAttribute(element, ContactConfigAttributeNames.APIKEY);
  const analyze = getDataAttribute(
    element,
    ContactConfigAttributeNames.ANALYZE,
  );
  const submit = getDataAttribute(element, ContactConfigAttributeNames.SUBMIT);
  const cookieIsEnabled = getDataAttribute(
    element,
    ContactConfigAttributeNames.COOKIE_ENABLED,
  );

  // return config
  return {
    container: DEFAULT_CONTACT_CONTAINER,
    account,
    apiKey,
    analyze,
    submit,
    cookieIsEnabled:
      cookieIsEnabled != null ? cookieIsEnabled === 'true' : undefined,
    categories: [],
  };
}

/**
 * Read contact & validate.
 *
 * @returns {ContactConfiguration}
 */
function readConfiguration(): ContactConfiguration {
  // read config
  const config =
    readConfigurationFromGlobal() || readConfigurationFromAttributes();

  // check if config is set
  if (config == null) {
    throw new Error(
      `OMQ Contact: Error in integration. No configuration found. Please check the documentation on how to integrate OMQ Contact.`,
    );
  }

  // get properties
  const { container, account, apiKey } = config;

  // get element
  const element = document.querySelector(container);
  if (!element) {
    throw new Error(
      `OMQ Contact: Error in integration. No HTML element was found for $\{element} .`,
    );
  }

  // check api key
  if (apiKey == null) {
    throw new Error(
      'OMQ Contact: Error in integration. Contact API Key is missing. Set attribute `data-omq-contact-api-key=CONTACT_API_KEY` to your contact container.',
    );
  }

  // check account
  if (account == null) {
    throw new Error(
      'OMQ Contact: Error in integration. Account name is missing. Set attribute `data-omq-contact-account=ACCOUNT_NAME` to your contact container.',
    );
  }

  return config;
}

/**
 * Update configuration.
 *
 * @param {?ContactConfiguration} config - Contact config
 */
function updateConfiguration(config: ?ContactConfiguration): void {
  if (config == null) {
    return;
  }

  // if config has been set via global object
  if (window._contact != null) {
    // update global object
    window._contact = { ...window._contact, config };
    return;
  }

  if (window.userlikeHelp != null) {
    // update global object
    window.userlikeHelp = { ...window.userlikeHelp, config };
    return;
  }
  // otherwise, update container attributes

  // get container element
  const element = document.querySelector(DEFAULT_CONTACT_CONTAINER);
  if (element == null) {
    return;
  }

  const { account, apiKey, analyze, submit, cookieIsEnabled } = config;

  // update account
  if (account != null) {
    setDataAttribute(element, ContactConfigAttributeNames.ACCOUNT, account);
  }

  // update apikey
  if (apiKey != null) {
    setDataAttribute(element, ContactConfigAttributeNames.APIKEY, apiKey);
  }

  // update analyze selector
  if (analyze != null) {
    setDataAttribute(element, ContactConfigAttributeNames.ANALYZE, analyze);
  }

  // update submit
  if (submit != null) {
    setDataAttribute(element, ContactConfigAttributeNames.SUBMIT, submit);
  }

  // update cookie
  if (cookieIsEnabled != null) {
    setDataAttribute(
      element,
      ContactConfigAttributeNames.COOKIE_ENABLED,
      cookieIsEnabled ? 'true' : 'false',
    );
  }
}

/**
 * Load style sheet for passed account.
 * Adds a link stylesheet tag to the head element.
 *
 * @param {string} account - name/url of account
 */
function loadStyleSheet(account: string): void {
  const link = document.createElement('link');
  const head = document.querySelector('head');

  // check if head is available
  if (head == null) {
    return;
  }

  // set link properties
  link.href = `${getBaseUrl(account) || ''}/contact/contact.min.css`;
  link.rel = 'stylesheet';
  link.id = 'omq-contact-stylesheet';

  // append to head
  head.appendChild(link);
}

/**
 * Handle error received from error-boundary.
 *
 * @param {Error} error - Error object
 * @param {?ErrorDetails} details - additional information
 */
function handleError(error: Error, details: ?ErrorDetails) {
  ErrorHandling.captureException(error, details, DSN);
}

/**
 * Return error view to display in case of an error.
 *
 * @param {Error} error - Error to display
 *
 * @returns Node
 */
function renderError(error: Error): Node {
  return (
    <div className="omq-contact error-boundary">
      <h1 className="error-boundary__headline">OMQ error</h1>
      <p className="error-boundary__text">An error occurred</p>
      <p className="error-boundary__message">{error.message}</p>
    </div>
  );
}

/**
 * Read answer placeholder from global object if set.
 *
 * @returns {Placeholder}
 */
export function readPlaceholder(): Placeholder {
  // read custom values / placeholders
  const placeholders =
    window.OMQContactPlaceholders || window.UserlikePlaceholders ||
    window.OMQContactCustomValues || window.UserlikeCustomValues || {};

  // clean props
  return validatePlaceholders(placeholders);
}

/**
 * Check given placeholders and remove invalid keys/values.
 *
 * @param placeholders
 *
 * @returns {Placeholder}
 */
export function validatePlaceholders(placeholders: {} = {}): Placeholder {
  // clean props
  return Object.keys(placeholders).reduce((result, key) => {
    if (typeof key === 'string') {
      result[key] = placeholders[key];
    }
    return result;
  }, {});
}

/**
 * Read config and render contact.
 */
function renderContactComponent(): void {
  const {
    container,
    account,
    apiKey,
    analyze,
    submit,
    cookieIsEnabled,
  } = readConfiguration();

  if (account == null || apiKey == null) {
    return;
  }

  // get element
  const element = document.querySelector(container);
  if (element == null) {
    return;
  }

  const placeholder = readPlaceholder();

  // render app
  ReactDOM.render(
    <StrictMode>
      <EventWrapper events={new ContactEvents(element)}>
        <ErrorBoundary onError={handleError} renderError={renderError}>
          <BackendWrapper account={account} apiKey={apiKey} path="contact">
            <InitialDataWrapper
              placeholder={placeholder}
              cookieName={`omq-contact-cookie-${apiKey}`}
              cookieIsEnabled={cookieIsEnabled}
              onReady={({ questions, config }) => {
                return (
                  <>
                    <SubmitHelpSession account={account} />
                    <Contact
                      ref={contactReference}
                      account={account}
                      apiKey={apiKey}
                      analyze={analyze}
                      submit={submit}
                      element={element}
                      defaultQuestions={questions}
                      placeholder={placeholder}
                      categories={config.categories}
                    />
                  </>
                );
              }}
            />
          </BackendWrapper>
        </ErrorBoundary>
      </EventWrapper>
    </StrictMode>,
    element,
  );
}

/**
 * Unmount contact component
 */
function unmountContactComponent(): void {
  const { container } = readConfiguration();
  const element = document.querySelector(container);

  if (element == null) {
    return;
  }

  ReactDOM.unmountComponentAtNode(element);
}

/**
 * Create global API to submit/update OMQ Contact
 */
function initPublicAPI() {
  window.OMQContact = window.UserlikeContact = {
    /**
     * Loads a question fully, were we do not have the answers for.
     *
     * @param id of the question
     * @returns found help question
     */
    loadQuestion: (id: number) => {
      return contactReference.current?.loadQuestion(id);
    },

    /**
     * Calls submit.
     */
    submit: () => {
      contactReference.current?.submit();
    },

    /**
     * Searches for the specific value and shows the result.
     *
     * @param value search value
     */
    search: (value: string) => {
      contactReference.current?.search(value);
    },

    /**
     * Sets custom values.
     *
     * @param customValues key-value pairs
     */
    setCustomValues: (customValues) => {
      contactReference.current?.setPlaceholder(
        validatePlaceholders(customValues),
      );
    },

    /**
     * Sets custom values.
     * Old interface for backwards compatibility.
     *
     * @param placeholders key-value pairs
     */
    setPlaceholders: (placeholders) => {
      contactReference.current?.setPlaceholder(
        validatePlaceholders(placeholders),
      );
    },

    /**
     * Update config & re-render contact.
     *
     * @param {?ContactConfiguration} config - contact config
     */
    update: (config: ?ContactConfiguration) => {
      if (config == null) {
        return;
      }

      // if account or api key has been changed
      // it's required to `restart` the app
      // (load style, create new session etc.)
      const unmountRequired = config.account != null || config.apiKey != null;

      if (unmountRequired) {
        // unmount component before update
        unmountContactComponent();

        // update
        updateConfiguration(config);

        // re-create contact
        createContact();
        return;
      }

      // update config
      updateConfiguration(config);

      // re-render component with new config
      renderContactComponent();
    },

    // set/update category search filter
    // set/update flag to perform search with empty text
    setCategory: (categoryId: number, triggerSearch?: boolean) => {
      if (contactReference.current != null) {
        contactReference.current.setCategory(categoryId, triggerSearch);
      }
    },

    mountOMQContact: renderContactComponent,
    unmountOMQContact: unmountContactComponent,
  };
}

/**
 * Create instance of Omq Contact.
 * Throws errors if configuration is missing/wrong.
 * Adds the Contact component to the DOM.
 */
export function createContact(): void {
  // read config
  const { account, apiKey } = readConfiguration();

  if (account == null || apiKey == null) {
    return;
  }

  // load styles
  loadStyleSheet(account);

  renderContactComponent();
  initPublicAPI();
}
