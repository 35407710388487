// @flow strict

import lodashUniqueId from 'lodash.uniqueid';

import Keyboard from '../enums/keyboard';

/**
 * Checks if the passed value is empty.
 *
 * Returns true if value is null, undefined.
 * If string is '' or if array has no items.
 *
 * TODO: rewrite, split into several functions e.g. isStringEmpty, isArrayEmpty
 * etc. to keep it type safe.
 *
 * @param {any} value
 *
 * @returns {boolean}
 */
export function isEmpty(value: ?mixed): boolean {
  // check for null and undefined
  if (value == null) {
    return true;
  }

  // check for empty string
  if (typeof value === 'string' && value === '') {
    return true;
  }

  // check for empty arrays
  return !!(Array.isArray(value) && value.length === 0);
}

/**
 * Create base url depending on account.
 * If account is not a real url, put as sub-domain of omq.de .
 *
 * todo: do not return null?
 *
 * @param {string} account - Name of account or URL of database
 *
 * @returns {string|null} Returns base url
 */
export function getBaseUrl(account: string): ?string {
  // if param is missing, return null
  if (isEmpty(account)) {
    return null;
  }

  // check if only account is passed
  // turn account into url
  if (!account.match('http://|https://')) {
    return `https://${account}.omq.de`;
  }

  // remove trailing slash
  return account.endsWith('/')
    ? account.substring(0, account.length - 1)
    : account;
}

export function isMobileDevice(): boolean {
  return window.matchMedia('(max-width: 991px)').matches;
}

/**
 * Types of documents readyState.
 *
 * @type {{COMPLETE: string, INTERACTIVE: string}}
 */
const ReadyState = {
  COMPLETE: 'complete',
  INTERACTIVE: 'interactive',
};

/**
 * Helper function to run code after
 * page load is done, and web page is ready.
 *
 * @returns {Promise<void>}
 */
export /* istanbul ignore next */ function documentReady(): Promise<void> {
  const readyState = document.readyState;

  // return promise
  return new Promise((resolve: () => void) => {
    // if document is ready, call resolve
    if (
      readyState === ReadyState.COMPLETE ||
      readyState === ReadyState.INTERACTIVE
    ) {
      resolve();
    } else {
      // otherwise, wait for dom content to be loaded
      const domLoadedHandler = () => {
        document.removeEventListener('DOMContentLoaded', domLoadedHandler);
        resolve();
      };

      document.addEventListener('DOMContentLoaded', domLoadedHandler);
    }
  });
}

/**
 * Set/update style of passed element.
 *
 * @param {Element|HTMLElement} element - Element to update
 * @param {Object} styles - Hash of styles (key:value)
 */
export function setStyle(element: ?HTMLElement, styles: {}): void {
  // check params
  if (element == null) {
    return;
  }

  Object.keys(styles).forEach((key) => {
    element.style[key] = styles[key];
  });
}

/**
 * Get the code for the pressed key form an event.
 * Since standard changed, and different browsers use
 * different names, check all of them, until one is found.
 *
 * @param {SyntheticKeyboardEvent<>} event - Browser event
 */
export function getKeyForEvent(
  event: SyntheticKeyboardEvent<>,
): string | number {
  // key property is new standard
  if (event.key !== undefined) {
    return event.key;
  }

  if (event.keyIdentifier !== undefined) {
    return event.keyIdentifier;
  }

  // support for older browsers
  if (event.keyCode !== undefined) {
    return event.keyCode;
  }

  return event.which;
}

/**
 * Check if the passed key code (given by keyboard event) is
 * the passed key.
 *
 * @param {string|number} key - Key from keyboard event
 * @param {string} name - Expected event name
 * @returns {boolean} - true if matches
 */
export function eventKeyIs(key: string | number, name: string): boolean {
  return key === name || key === Keyboard.KEYBOARD_KEY_CODES[name];
}

/**
 * Convert any kind of language code into a
 * 2 letter lower case language.
 *
 * E.g. `en_US` returns `en`
 *
 * @param {string} code - Language code
 * @returns {string} - normalized language code
 */
export function getNormalizedLanguageCode(code: string = ''): string {
  return code.substr(0, 2).toLowerCase();
}

/**
 * Format string to replace placeholders with given params.
 *
 * @param {string} str - string to format
 * @param {Array<string>} params - Params to fill in
 *
 * @returns {string} - Formatted string
 */
export function formatString(str: string, params: Array<string>): string {
  if (str == null) {
    return '';
  }

  // check for params
  if (params == null || params.length === 0) {
    return str;
  }

  // replace all placeholders with passed params
  return params.reduce((result, param, idx) => {
    return result.replace(new RegExp('\\{' + idx + '\\}', 'g'), param);
  }, str);
}

/**
 * Return a human-readable string of the given timestamp.
 *
 * @param {number} timestamp - time stamp
 * @param {{language}} options - options
 *
 * @returns {string}
 */
export function printDate(
  timestamp: number,
  { language }: { language: string } = { language: 'de' },
): string {
  if (timestamp == null) {
    return '';
  }

  const date = new Date();
  date.setTime(timestamp);

  return date.toLocaleDateString(language, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
}

export function capitalize(value: string): string {
  return value
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
    .join('');
}

/** If available, replace hash function * */

/**
 * Simple hash function.
 *
 * @param {string} str - String to hash
 * @returns {number|string} Hash of passed string
 */
export function hashCode(str: string): string | number {
  let res = 0;

  if (!str) {
    return '';
  }

  const len = str.length;

  for (let i = 0; i < len; i += 1) {
    res = res * 31 + str.charCodeAt(i);
  }

  return res;
}

/**
 * Wrap lodash uniqueId function
 *
 * @param {string} prefix - prefix for generated id
 *
 * @returns {string}
 */
export function uniqueId(prefix: string): string {
  return lodashUniqueId(prefix);
}
