// @flow strict

import { type Node, useState, useEffect } from 'react';

import { type Question, Placeholder } from '@omq/flow';
import {ServerError, useBackendContext, useConfigContext} from '@omq/shared';

import { QuestionListItem } from './question-list-item';
import { ContactAPI } from "../../api/contact";

import './question-list.less';

/**
 * Type for component properties.
 */
type QuestionListProps = {
  questions: Array<Question>,
  headline: string,
  selectedQuestionId: number | null,
  onQuestionSelect: (questionId: number | null) => void,
  renderEmptyList: () => Node,
  placeholder: Placeholder,
  onError: (error: ServerError) => void
};

/**
 * QuestionList component. Renders a list of questions.
 *
 * @param {QuestionListProps} props - Component properties
 *
 * @author Florian Walch
 * @since 9.3
 *
 * @returns {Node}
 */
export function QuestionList(
  {
    questions,
    headline,
    selectedQuestionId,
    onQuestionSelect,
    renderEmptyList,
    placeholder,
    onError,
  }: QuestionListProps): Node {
  const [selectedQuestion, setSelectedQuestion] = useState<HelpQuestion | null>(null);

  const backend = useBackendContext();
  const config = useConfigContext();

  // loads the selected question if not already there
  useEffect(() => {
    // reset selected question if id is null
    if (selectedQuestionId == null) {
      setSelectedQuestion(null);
      return;
    }

    // find selected question
    const currentQuestion = questions.find((item) => item.id === selectedQuestionId)

    // Lazy load question content on click if answers have not been loaded yet
    if (currentQuestion && !currentQuestion.answers) {
      ContactAPI.question(backend, selectedQuestionId, placeholder)
        .then(async (result) => {
          // Set answers to the question
          setSelectedQuestion(result)

          onError(null);
        })
        .catch((error) => {
          console.error("Error loading question", selectedQuestionId);
          onError(error);
        });
    }
    // sets the selected question
    else {
      setSelectedQuestion(currentQuestion);
    }
  }, [selectedQuestionId]);

  // if list is empty
  if (questions.length === 0) {
    return (
      <div className={config.generateClassName('question-list')}>
        <div className={config.generateClassName('question-list__empty')}>
          {renderEmptyList()}
        </div>
      </div>
    );
  }

  let className = config.generateClassName('question-list');
  if (selectedQuestion != null) {
    className += ` ${config.generateClassName('question-list--has-selection')}`;
  }

  // render list
  return (
    <div className={className}>
      <div className={config.generateClassName('question-list__header')}>
        {headline}
      </div>
      {questions.map((question) => (
        <QuestionListItem
          key={question.id}
          question={question.id === selectedQuestion?.id ? selectedQuestion : question}
          isActive={selectedQuestion?.id === question.id}
          onSelect={onQuestionSelect}
        />
      ))}
    </div>
  );
}
